// 获取快讯频道
export const GETFLASHCHANNEL = 'get-flash-channel'

// 获取精选快讯
export const GETIMPORTANTFLASH = 'get-important-flash'

// 获取新闻排行
export const GETNEWSRANKINGS = 'get-news-rankings'

// 获取快讯详情
export const GETFLASHDETAILS = 'get-flash-details'

// 获取详情利好利空
export const DETAILSUPORDOWN = 'details-up-or-down'
